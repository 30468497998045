.page-home .page__wrapper {
  flex-direction: column;
  justify-content: center;
  display: flex;
}

.page-home__content {
  z-index: 3;
  flex-grow: 1;
  grid-template-columns: 10fr 8fr;
  column-gap: 3em;
  display: grid;
  position: relative;
}

@media screen and (max-width: 1365px) {
  .page-home__content {
    flex-direction: column;
    display: flex;
  }
}

.page-home__content .col {
  min-width: 0;
  min-height: 0;
  flex-direction: column;
  display: flex;
}

.page-home__content .col p {
  margin-top: 0;
  margin-bottom: 1em;
}

.page-home__content .col p:last-of-type {
  margin-bottom: 0;
}

.page-home__content .col-1 h1 {
  margin-top: 0;
  margin-bottom: 1em;
  font-size: 2em;
}

.page-home__content .col-1 h1 span {
  font-size: 1.6em;
  display: block;
}

@media screen and (max-width: 1365px) {
  .page-home__content .col-1 h1 {
    margin-bottom: 1em;
  }
}

@media screen and (max-width: 768px) {
  .page-home__content .col-1 h1 {
    font-size: 1.7em;
  }
}

.page-home__content .col-1 > p {
  max-width: 85%;
  display: block;
}

@media screen and (max-width: 768px) {
  .page-home__content .col-1 > p {
    max-width: 100%;
  }
}

.page-home__content .col-1 .button {
  margin-top: 2em;
  margin-right: auto;
}

@media screen and (max-width: 768px) {
  .page-home__content .col-1 .button {
    margin-left: auto;
  }
}

.page-home__content .col-1 .slider-container {
  height: 140px;
  margin: 1em 0;
  padding-left: 2em;
  font-size: 1.2em;
  font-style: italic;
}

@media screen and (max-width: 1365px) {
  .page-home__content .col-1 .slider-container {
    padding-left: 0;
  }
}

@media screen and (max-width: 768px) {
  .page-home__content .col-1 .slider-container {
    height: 170px;
  }
}

.page-home__content .col-1 .slider-container li.splide__slide {
  padding: 0 1em;
}

.page-home__content .col-1 .slider-container .splide__pagination {
  justify-content: flex-start;
  padding-left: 3em;
}

@media screen and (max-width: 1365px) {
  .page-home__content .col-1 .slider-container .splide__pagination {
    padding-left: 1em;
  }
}

@media screen and (max-width: 768px) {
  .page-home__content .col-1 .slider-container .splide__pagination {
    justify-content: center;
    padding-left: 0;
  }
}

.page-home__content .col-1 .slider-container .splide__pagination__page {
  opacity: 1;
  border-radius: 0;
  margin: 3px 6px;
}

.page-home__content .col-1 .slider-container .splide__pagination__page.is-active {
  background: #00ffa3;
}

.page-home__content .col-1 .slider-container p {
  margin: 0;
}

.page-home__content .col-1 .slider-container p:first-of-type {
  margin-bottom: 1em;
}

.page-home__content .col-2 {
  justify-content: center;
}

@media screen and (max-width: 1365px) {
  .page-home__content .col-2 {
    margin-top: 2em;
  }
}

.page-home__content .col-2 h2 {
  text-align: right;
  margin-top: 0;
  margin-bottom: .5em;
  font-size: 32px;
}

@media screen and (max-width: 768px) {
  .page-home__content .col-2 h2 {
    text-align: center;
  }
}

.page-home__content .video-box {
  height: 350px;
  margin-bottom: 2em;
}

@media screen and (max-width: 1365px) {
  .page-home__content .video-box {
    height: 50vw;
  }
}

.page-home__content .video-box iframe {
  height: 100% !important;
}

.page-home__content .buttons {
  flex-direction: column;
  display: flex;
}

.page-home__content .buttons .button:not(.page-home__content .buttons .button:last-of-type) {
  margin-bottom: 1.5em;
}

.page-home .page__footer {
  background-color: rgba(0, 0, 0, 0);
}

.page__mask {
  width: 150%;
  height: 155.093vh;
  transform-origin: 0 0;
  background-color: #fff;
  position: absolute;
  bottom: 0;
  left: 0;
  transform: translateY(100%)rotate(-18deg);
}

@media screen and (min-width: 1921px) {
  .page__mask {
    height: 2400px;
    transform: translateY(100%)rotate(-15deg);
  }
}

@media screen and (min-width: 2500px) {
  .page__mask {
    transform: translateY(100%)rotate(-11deg);
  }
}

@media screen and (max-width: 1880px) {
  .page__mask {
    transform: translateY(100%)rotate(-19deg);
  }
}

@media screen and (max-width: 1600px) {
  .page__mask {
    transform: translateY(100%)rotate(-21deg);
  }
}

@media screen and (max-width: 1450px) {
  .page__mask {
    transform: translateY(100%)rotate(-24deg);
  }
}

@media screen and (max-width: 1365px) {
  .page__mask {
    height: 400px;
    transform: translateY(0);
  }
}

@media screen and (max-width: 768px) {
  .page__mask {
    height: 600px;
    transform: translateY(0);
  }
}

@media screen and (max-width: 400px) {
  .page__mask {
    height: 650px;
    transform: translateY(0);
  }
}

@media screen and (max-width: 350px) {
  .page__mask {
    height: 690px;
    transform: translateY(0);
  }
}

.page-guide__content {
  width: min(1440px, 92vw);
  z-index: 3;
  grid-template-columns: 5fr 2fr;
  column-gap: 2em;
  margin: 0 auto;
  display: grid;
  position: relative;
}

@media screen and (max-width: 1365px) {
  .page-guide__content {
    grid-template-columns: 1fr;
  }
}

.page-guide__content .col {
  flex-direction: column;
  display: flex;
}

.page-guide__content .col-1 h1 {
  text-align: right;
}

@media screen and (max-width: 1365px) {
  .page-guide__content .col-1 h1 {
    text-align: center;
  }
}

.page-guide__content .col-1 .video-box {
  height: 530px;
}

.page-guide__content .col-1 .video-box iframe {
  height: 100% !important;
}

@media screen and (max-width: 1365px) {
  .page-guide__content .col-1 .video-box {
    width: 760px;
    height: 456px;
    margin: 0 auto;
  }
}

@media screen and (max-width: 800px) {
  .page-guide__content .col-1 .video-box {
    width: 100%;
    margin: 0 auto;
    height: 300px !important;
  }
}

.page-guide__content .col-2 {
  flex-direction: column;
  justify-content: center;
  display: flex;
}

.page-guide__content .col-2 .tile:first-of-type {
  margin-bottom: 2em;
}

@media screen and (max-width: 1365px) {
  .page-guide__content .col-2 {
    flex-direction: row;
    margin-top: 2em;
  }

  .page-guide__content .col-2 .tile:first-of-type {
    margin-bottom: 0;
    margin-right: 2em;
  }
}

@media screen and (max-width: 800px) {
  .page-guide__content .col-2 {
    flex-direction: column;
    align-items: center;
  }

  .page-guide__content .col-2 .tile:first-of-type {
    margin-bottom: 2em;
    margin-right: 0;
  }
}

.page__header {
  z-index: 3;
  justify-content: flex-start;
  padding: 1em 0;
  display: flex;
  position: relative;
}

.page__header a {
  align-items: center;
  display: flex;
}

.page__header a img {
  margin-right: 3em;
}

html {
  overflow-x: hidden;
}

html body {
  color: #fff;
  margin: 0;
  font-family: Poppins, Open Sans, sans-serif;
  font-size: 16px;
  font-weight: 700;
}

@media screen and (max-width: 1366px) {
  html body {
    font-size: 15px;
  }
}

@media screen and (max-width: 768px) {
  html body {
    font-size: 14px;
  }
}

html body * {
  box-sizing: border-box;
}

html body a {
  color: #00ffa3;
  text-decoration: none;
}

html body .button {
  cursor: pointer;
  justify-content: center;
  align-items: center;
  padding: .8em 5em;
  transition: all .14s ease-in-out;
  display: inline-flex;
  position: relative;
  box-shadow: 2px 1px 4px rgba(0, 0, 0, .16), 2px 1px 4px rgba(0, 0, 0, .23);
}

html body .button--mint {
  color: #000;
  background-color: #00ffa3;
}

html body .button--orange {
  color: #fff;
  background: linear-gradient(135deg, #e3771c 0%, #f56741 100%);
}

html body .button--text-xl {
  font-size: 1.15em;
}

html body .button__content {
  color: inherit;
  text-align: center;
  align-items: center;
  display: inline-flex;
}

html body .button__icon {
  position: absolute;
  top: 50%;
  right: 1em;
  transform: translate(0, -50%);
}

html body .button__icon img {
  max-width: 100%;
  max-height: 100%;
  display: block;
}

@media (hover: hover) and (pointer: fine) {
  html body .button:hover {
    transform: translateY(-3px);
  }
}

html body .tile {
  width: 290px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: .8em 2em;
  transition: all .14s ease-in-out;
  display: flex;
  position: relative;
  box-shadow: 2px 1px 4px rgba(0, 0, 0, .16), 2px 1px 4px rgba(0, 0, 0, .23);
}

html body .tile--mint {
  color: #000;
  background-color: #00ffa3;
}

html body .tile--orange {
  color: #fff;
  background: linear-gradient(30deg, #f2892b 0%, #f56741 100%);
}

html body .tile__content {
  text-align: center;
  text-align: center;
  margin-bottom: 1em;
}

html body .tile__icon {
  margin-bottom: .5em;
}

html body .tile__icon img {
  max-width: 100%;
  max-height: 100%;
  display: block;
}

html body .tile__info {
  color: inherit;
  margin-bottom: .5em;
  font-size: .9em;
}

@media (hover: hover) and (pointer: fine) {
  html body .tile:hover {
    transform: translateY(-3px);
  }
}

html body .inject-video-onclick {
  overflow: hidden;
}

html body .inject-video-onclick .video-inject-invoker {
  height: 100%;
  cursor: pointer;
  background-color: #000;
  position: relative;
}

html body .inject-video-onclick .video-inject-invoker.hidden {
  display: none;
}

html body .inject-video-onclick .video-inject-invoker.loading .load-icon {
  display: block;
}

html body .inject-video-onclick .video-inject-invoker.loading .play-icon {
  display: none;
}

html body .inject-video-onclick .video-inject-invoker img {
  height: 100%;
  width: 100%;
  object-fit: contain;
  display: block;
}

html body .inject-video-onclick .video-inject-invoker .load-icon {
  display: none;
}

html body .inject-video-onclick .video-inject-invoker .icon {
  width: 65px;
  z-index: 2;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

html body .inject-video-onclick .video-inject-invoker .icon svg {
  width: 100%;
}

html body .inject-video-onclick .video-inject-invoker:after {
  content: "";
  z-index: 1;
  background-color: rgba(0, 0, 0, .35);
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

html body .page {
  min-height: 100vh;
  background: linear-gradient(135deg, #e3771c 0%, #f56741 100%);
  flex-direction: column;
  display: flex;
  position: relative;
  overflow: hidden;
}

html body .page__wrapper {
  width: min(1680px, 92vw);
  flex-grow: 1;
  margin: 0 auto;
}

html body .page__decoration {
  height: 650px;
  pointer-events: none;
  position: absolute;
  left: max(50vw - 840px, 4vw);
}

@media screen and (max-width: 768px) {
  html body .page__decoration {
    height: auto;
    width: 94vw;
    top: 50px;
    left: 2vw;
  }
}

html body .page__decoration .image-box {
  height: 100%;
  position: relative;
}

html body .page__decoration .image-box img {
  opacity: .8;
  max-height: 100%;
  max-width: 100%;
  filter: invert(23%) sepia(33%) saturate(4980%) hue-rotate(356deg) brightness(93%) contrast(86%);
}

html body .page__decoration .image-box:after {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

html body .page__footer {
  color: #000;
  height: 150px;
  z-index: 3;
  background-color: #fff;
  flex-direction: column;
  align-items: center;
  margin-top: 2em;
  font-size: 14px;
  font-weight: 400;
  display: flex;
}

@media screen and (max-width: 1366px) {
  html body .page__footer {
    height: auto;
  }
}

html body .page__footer .content {
  width: min(800px, 92vw);
  text-align: center;
  margin: 0 auto;
  padding: 1em 0;
}

html body .page__footer .images-container {
  flex-wrap: wrap;
  justify-content: center;
  display: flex;
}

html body .page__footer .images-container img {
  display: block;
}

.splide__container {
  box-sizing: border-box;
  position: relative;
}

.splide__list {
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  height: 100%;
  display: flex;
  margin: 0 !important;
  padding: 0 !important;
}

.splide.is-initialized:not(.is-active) .splide__list {
  display: block;
}

.splide__pagination {
  pointer-events: none;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  margin: 0;
  display: flex;
}

.splide__pagination li {
  pointer-events: auto;
  margin: 0;
  line-height: 1;
  list-style-type: none;
  display: inline-block;
}

.splide__progress__bar {
  width: 0;
}

.splide {
  visibility: hidden;
  position: relative;
}

.splide.is-initialized, .splide.is-rendered {
  visibility: visible;
}

.splide__slide {
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  box-sizing: border-box;
  flex-shrink: 0;
  margin: 0;
  position: relative;
  list-style-type: none !important;
}

.splide__slide img {
  vertical-align: bottom;
}

.splide__spinner {
  contain: strict;
  height: 20px;
  width: 20px;
  border: 2px solid #999;
  border-left-color: rgba(0, 0, 0, 0);
  border-radius: 50%;
  margin: auto;
  animation: splide-loading 1s linear infinite;
  display: inline-block;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.splide__sr {
  clip: rect(0 0 0 0);
  height: 1px;
  width: 1px;
  border: 0;
  margin: -1px;
  padding: 0;
  position: absolute;
  overflow: hidden;
}

.splide__toggle.is-active .splide__toggle__play, .splide__toggle__pause {
  display: none;
}

.splide__toggle.is-active .splide__toggle__pause {
  display: inline;
}

.splide__track {
  z-index: 0;
  position: relative;
  overflow: hidden;
}

@keyframes splide-loading {
  0% {
    transform: rotate(0);
  }

  to {
    transform: rotate(1turn);
  }
}

.splide__track--draggable {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.splide__track--fade > .splide__list {
  display: block;
}

.splide__track--fade > .splide__list > .splide__slide {
  opacity: 0;
  z-index: 0;
  position: absolute;
  top: 0;
  left: 0;
}

.splide__track--fade > .splide__list > .splide__slide.is-active {
  opacity: 1;
  z-index: 1;
  position: relative;
}

.splide--rtl {
  direction: rtl;
}

.splide__track--ttb > .splide__list {
  display: block;
}

.splide__arrow {
  cursor: pointer;
  height: 2em;
  opacity: .7;
  width: 2em;
  z-index: 1;
  background: #ccc;
  border: 0;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  padding: 0;
  display: flex;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.splide__arrow svg {
  fill: #000;
  height: 1.2em;
  width: 1.2em;
}

.splide__arrow:hover:not(:disabled) {
  opacity: .9;
}

.splide__arrow:disabled {
  opacity: .3;
}

.splide__arrow:focus-visible {
  outline-offset: 3px;
  outline: 3px solid #0bf;
}

.splide__arrow--prev {
  left: 1em;
}

.splide__arrow--prev svg {
  transform: scaleX(-1);
}

.splide__arrow--next {
  right: 1em;
}

.splide.is-focus-in .splide__arrow:focus {
  outline-offset: 3px;
  outline: 3px solid #0bf;
}

.splide__pagination {
  z-index: 1;
  padding: 0 1em;
  position: absolute;
  bottom: .5em;
  left: 0;
  right: 0;
}

.splide__pagination__page {
  height: 8px;
  opacity: .7;
  width: 8px;
  background: #ccc;
  border: 0;
  border-radius: 50%;
  margin: 3px;
  padding: 0;
  transition: transform .2s linear;
  display: inline-block;
  position: relative;
}

.splide__pagination__page.is-active {
  z-index: 1;
  background: #fff;
  transform: scale(1.4);
}

.splide__pagination__page:hover {
  cursor: pointer;
  opacity: .9;
}

.splide__pagination__page:focus-visible {
  outline-offset: 3px;
  outline: 3px solid #0bf;
}

.splide.is-focus-in .splide__pagination__page:focus {
  outline-offset: 3px;
  outline: 3px solid #0bf;
}

.splide__progress__bar {
  height: 3px;
  background: #ccc;
}

.splide__slide {
  -webkit-tap-highlight-color: transparent;
}

.splide__slide:focus {
  outline: 0;
}

@supports (outline-offset:-3px) {
  .splide__slide:focus-visible {
    outline-offset: -3px;
    outline: 3px solid #0bf;
  }
}

@media screen and (-ms-high-contrast: none) {
  .splide__slide:focus-visible {
    border: 3px solid #0bf;
  }
}

@supports (outline-offset:-3px) {
  .splide.is-focus-in .splide__slide:focus {
    outline-offset: -3px;
    outline: 3px solid #0bf;
  }
}

@media screen and (-ms-high-contrast: none) {
  .splide.is-focus-in .splide__slide:focus {
    border: 3px solid #0bf;
  }

  .splide.is-focus-in .splide__track > .splide__list > .splide__slide:focus {
    border-color: #0bf;
  }
}

.splide__toggle {
  cursor: pointer;
}

.splide__toggle:focus-visible {
  outline-offset: 3px;
  outline: 3px solid #0bf;
}

.splide.is-focus-in .splide__toggle:focus {
  outline-offset: 3px;
  outline: 3px solid #0bf;
}

.splide__track--nav > .splide__list > .splide__slide {
  cursor: pointer;
  border: 3px solid rgba(0, 0, 0, 0);
}

.splide__track--nav > .splide__list > .splide__slide.is-active {
  border: 3px solid #000;
}

.splide__arrows--rtl .splide__arrow--prev {
  left: auto;
  right: 1em;
}

.splide__arrows--rtl .splide__arrow--prev svg {
  transform: scaleX(1);
}

.splide__arrows--rtl .splide__arrow--next {
  left: 1em;
  right: auto;
}

.splide__arrows--rtl .splide__arrow--next svg {
  transform: scaleX(-1);
}

.splide__arrows--ttb .splide__arrow {
  left: 50%;
  transform: translate(-50%);
}

.splide__arrows--ttb .splide__arrow--prev {
  top: 1em;
}

.splide__arrows--ttb .splide__arrow--prev svg {
  transform: rotate(-90deg);
}

.splide__arrows--ttb .splide__arrow--next {
  top: auto;
  bottom: 1em;
}

.splide__arrows--ttb .splide__arrow--next svg {
  transform: rotate(90deg);
}

.splide__pagination--ttb {
  flex-direction: column;
  padding: 1em 0;
  display: flex;
  top: 0;
  bottom: 0;
  left: auto;
  right: .5em;
}

/*# sourceMappingURL=main.a854a2e7.css.map */
