.page{
    &-home{
        .page__wrapper{
            display: flex;
            flex-direction: column;
            justify-content: center;
        }
        &__content{
            display: grid;
            grid-template-columns: 10fr 8fr;
            column-gap: 3em;
            z-index: 3;
            flex-grow: 1;

            position: relative;

            @media screen and (max-width: 1365px) {
                display: flex;
                flex-direction: column;
            }

            .col{
                display: flex;
                flex-direction: column;
                
                min-width: 0;
                min-height: 0;
                p{
                    margin-bottom: 1em;
                    margin-top: 0;

                    &:last-of-type{
                        margin-bottom: 0;
                    }
                }

                &-1{
                    h1{
                        font-size: 2em;
                        margin-bottom: 1em;
                        margin-top: 0;
                        span{
                            display: block;
                            font-size: 1.6em;
                        }
                        @media screen and (max-width: 1365px) {
                            margin-bottom: 1em;
                        }
                        @media screen and (max-width: 768px) {
                            font-size: 1.7em;
                        }
                    }

                    >p{
                        display: block;
                        max-width: 85%;

                        @media screen and (max-width: 768px) {
                            max-width: 100%;
                        }
                    }

                    .button{
                        margin-right: auto;
                        margin-top: 2em;
                        @media screen and (max-width: 768px) {
                            margin-left: auto;
                        }
                    }

                    .slider-container{
                        height: 140px;
                        padding-left: 2em;
                        font-size: 1.2em;
                        font-style: italic;
                        margin: 1em 0 1em;

                        @media screen and (max-width: 1365px) {
                            padding-left: 0;    
                        }

                        @media screen and (max-width: 768px) {
                            height: 170px;   
                        }
                        
                        li.splide__slide{
                            padding: 0 1em;
                        }

                        .splide__pagination{
                            padding-left: 3em;
                            justify-content: flex-start;
                            @media screen and (max-width: 1365px) {
                                padding-left: 1em;    
                            }
                            @media screen and (max-width: 768px) {
                                padding-left: 0;
                                justify-content: center;
                            }

                            &__page{
                                border-radius: 0;
                                opacity: 1;
                                margin: 3px 6px;
                                &.is-active{
                                    background: $mint;
                                }
                            }
                        }

                        p{
                            margin: 0;
                            &:first-of-type{
                                margin-bottom: 1em
                            }
                        }
                    }
                }

                &-2{
                    justify-content: center;
                    @media screen and (max-width: 1365px) {
                        margin-top: 2em;
                    }
                    h2{
                        font-size: 32px;
                        text-align: right;
                        margin-top: 0;
                        margin-bottom: 0.5em;

                        @media screen and (max-width: 768px) {
                            text-align: center;
                        }
                    }
                }

            }

            .video-box{
                height: 350px;
                margin-bottom: 2em;

                @media screen and (max-width: 1365px) {
                    height: 50vw;
                }

                iframe{
                    height: 100% !important;
                }
            }

            .buttons{
                display: flex;
                flex-direction: column;
                .button{
                    &:not(&:last-of-type){
                        margin-bottom: 1.5em;
                    }
                }
            }
        }

        .page__footer{
            background-color: transparent;
        }
    }

    &__mask{
        position: absolute;
        bottom: 0;
        left: 0;
        width: 150%;
        height: 155.093vh;
        background-color: white;
        transform: translateY(100%) rotate(-18deg);
        transform-origin: top left;

        @media screen and (min-width: 1921px) {
            height: 2400px;
            transform: translateY(100%) rotate(-15deg);
        }
        @media screen and (min-width: 2500px) {
            transform: translateY(100%) rotate(-11deg);
        }

        @media screen and (max-width: 1880px) {
            transform: translateY(100%) rotate(-19deg);
        }

        @media screen and (max-width: 1600px) {
            transform: translateY(100%) rotate(-21deg);
        }

        @media screen and (max-width: 1450px) {
            transform: translateY(100%) rotate(-24deg);
        }

        @media screen and (max-width: 1365px) {
            height: 400px;
            transform: translateY(0);
        }

        @media screen and (max-width: 768px) {
            height: 600px;
            transform: translateY(0);
        }

        @media screen and (max-width: 400px) {
            height: 650px;
            transform: translateY(0);
        }

        @media screen and (max-width: 350px) {
            height: 690px;
            transform: translateY(0);
        }

        @media (orientation: portrait) {
            // height: 55vh;
            // transform: translateY(100%) rotate(-15deg);
            // // max-height: 50vh;
        }
    }

}