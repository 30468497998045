.page{
    &__header{
        z-index: 3;
        position: relative;
        display: flex;
        justify-content: flex-start;
        padding: 1em 0;

        a{
            display: flex;
            align-items: center;
            img{
                margin-right: 3em;
            }
        }
    }
}