@import 'variables.scss';
@import 'home.scss';
@import 'guide.scss';
@import 'page-header.scss';

html{
    overflow-x: hidden;

    body{
        font-family: 'Poppins', 'Open Sans', sans-serif;
        font-size: 16px;
        font-weight: 700;
        margin: 0;
        color: white;

        @media screen and (max-width: 1366px) {
            font-size: 15px;
        }

        @media screen and (max-width: 768px) {
            font-size: 14px;
        }

        *{
            box-sizing: border-box;
        }

        a{
            text-decoration: none;
            color: $mint;
        }

        .button{
            display: inline-flex;
            justify-content: center;
            align-items: center;
            padding: 0.8em 5em;
            box-shadow: 2px 1px 4px rgba(0,0,0,0.16), 2px 1px 4px rgba(0,0,0,0.23);
            position: relative;
            transition: 0.14s ease-in-out all;
            cursor: pointer;

            &--mint{
                background-color: $mint;
                color: black;
            }

            &--orange{
                background: linear-gradient(135deg, $orange-light 0%, $orange-dark 100%);
                color: white;
            }

            &--text-xl{
                font-size: 1.15em;
            }

            &__content{
                color: inherit;
                display: inline-flex;
                align-items: center;
                text-align: center;
            }

            &__icon{
                position: absolute;
                right: 1em;
                top: 50%;
                transform: translate(0, -50%);

                img{
                    display: block;
                    max-width: 100%;
                    max-height: 100%;

                }
            }

            @media (hover: hover) and (pointer: fine){
                &:hover{
                    transform: translateY(-3px);
                }
            }
        }

        .tile{
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            padding: 0.8em 2em;
            box-shadow: 2px 1px 4px rgba(0,0,0,0.16), 2px 1px 4px rgba(0,0,0,0.23);
            position: relative;
            transition: 0.14s ease-in-out all;
            width: 290px;

            &--mint{
                background-color: $mint;
                color: black;
            }

            &--orange{
                background: linear-gradient(30deg, #f2892b 0%, $orange-dark 100%);
                color: white;
            }

            &__content{
                text-align: center;
                margin-bottom: 1em;
                text-align: center;
            }

            &__icon{
                margin-bottom: 0.5em;
                img{
                    display: block;
                    max-width: 100%;
                    max-height: 100%;
                }
            }

            &__info{
                margin-bottom: 0.5em;
                color: inherit;
                font-size: 0.9em;
            }

            @media (hover: hover) and (pointer: fine){
                &:hover{
                    transform: translateY(-3px);
                }
            }
        }

        .inject-video-onclick{
            overflow: hidden;
            
            .video-inject-invoker{
                height: 100%;
                background-color: black;
                cursor: pointer;
                position: relative;

                &.hidden{
                    display: none;
                }

                &.loading{
                    .load-icon{
                        display: block;
                    }
                    .play-icon{
                        display: none;
                    }
                }

                img{
                    height: 100%;
                    display: block;
                    width: 100%;
                    object-fit: contain;
                }

                .load-icon{
                    display: none;
                }

                .icon{
                    width: 65px;
                    top: 50%;
                    left: 50%;
                    position: absolute;
                    transform: translate(-50%, -50%);
                    z-index: 2;
                    svg{
                        width: 100%;
                    }
                }

                &::after{
                    content: '';
                    position: absolute;
                    top: 0;
                    left: 0;
                    bottom: 0;
                    right: 0;
                    background-color: rgba(0,0,0,0.35);
                    z-index: 1;
                }
            }
        }

        .page{
            position: relative;
            display: flex;
            flex-direction: column;
            overflow: hidden;
            background: linear-gradient(135deg, $orange-light 0%, $orange-dark 100%);
            min-height: 100vh;

            &__wrapper{
                width: min(1680px, 92vw);
                margin: 0 auto;
                flex-grow: 1;
            }

            &__decoration{
                position: absolute;
                left: max(calc((100vw - 1680px) / 2), 4vw);
                height: 650px;
                pointer-events: none;
                @media screen and (max-width: 768px) {
                    left: 2vw;
                    top: 50px;
                    height: auto;
                    width: 94vw;
                }
                .image-box{
                    position: relative;
                    height: 100%;
                    img{
                        opacity: 0.8;
                        max-height: 100%;
                        max-width: 100%;
                        filter: invert(23%) sepia(33%) saturate(4980%) hue-rotate(356deg) brightness(93%) contrast(86%);
                    }

                    &:after{
                        content: '';
                        position: absolute;
                        top: 0;
                        left: 0;
                        bottom: 0;
                        right: 0;
                    }
                }
            }

            &__footer{
                margin-top: 2em;
                background-color: white;
                color: black;
                display: flex;
                flex-direction: column;
                align-items: center;
                font-weight: 400;
                font-size: 14px;
                height: $footer-height;
                z-index: 3;

                @media screen and (max-width: 1366px) {
                    height: auto;
                }

                .content{
                    width: min(800px, 92vw);
                    margin: 0 auto;
                    padding: 1em 0;
                    text-align: center;
                }

                .images-container{
                    display: flex;
                    justify-content: center;
                    flex-wrap: wrap;
                    img{
                        // max-width: 45%;
                        display: block;
                    }

                    @media screen and (max-width: 768px) {
                        // display:grid;
                        // grid-template-columns: 1fr 1fr;
                        // align-items: center;
                        // justify-items: center;
                    }
                }
            }
        }
    }
}