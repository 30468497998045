.page{
    &-guide{
        &__content{
            width: min(1440px, 92vw);
            margin: 0 auto;
            z-index: 3;
            position: relative;
            display: grid;
            grid-template-columns: 5fr 2fr;
            column-gap: 2em;

            @media screen and (max-width: 1365px) {
                grid-template-columns: 1fr;
            }

            .col{
                display: flex;
                flex-direction: column;

                &-1{
                    h1{
                        text-align: right;
                        @media screen and (max-width: 1365px){
                            text-align: center;
                        }
                    }

                    .video-box{
                        height: 530px;
                        iframe{
                            height: 100% !important;
                        }

                        @media screen and (max-width: 1365px){
                            width: 760px;
                            height: calc(760px * 3/5);
                            margin: 0 auto;
                        }

                        @media screen and (max-width: 800px){
                            width: 100%;
                            height: 300px !important;
                            margin: 0 auto;
                        }
                    }
                }
    
                &-2{
                    display: flex;
                    flex-direction: column;
                    justify-content: center;

                    .tile{
                        &:first-of-type{
                            margin-bottom: 2em;
                        }
                    }

                    @media screen and (max-width: 1365px) {
                        margin-top: 2em;
                        flex-direction: row;

                        .tile{
                            &:first-of-type{
                                margin-bottom: 0;
                                margin-right: 2em;
                            }
                        }
                    }

                    @media screen and (max-width: 800px){
                        flex-direction: column;
                        align-items: center;

                        .tile{
                            &:first-of-type{
                                margin-right: 0em;
                                margin-bottom: 2em;
                            }
                        }
                    }
                }
            }

        }
    }
}